const { serverRuntimeConfig } = require('next/config').default();
const { secretKey } = serverRuntimeConfig;

const getCrypto = () => (typeof window !== 'undefined' ? window.crypto : global.crypto);
const getBtoa = () => (typeof window !== 'undefined' ? window.btoa : global.btoa);
const getAtob = () => (typeof window !== 'undefined' ? window.atob : global.atob);

function arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return getBtoa()(binary);
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = getAtob()(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

const generateKey = async (phrase: string): Promise<Uint8Array> => {
    const encoder = new TextEncoder();
    const encodedPhrase = encoder.encode(phrase);
    const hashBuffer = await getCrypto().subtle.digest('SHA-256', encodedPhrase);
    const hashArray = new Uint8Array(hashBuffer);
    return hashArray.slice(0, 32);
};

export const encryptClient = async (data: string): Promise<string> => {
    const key = await getCrypto().subtle.importKey(
        'raw',
        await generateKey(secretKey),
        'AES-GCM',
        false,
        ['encrypt', 'decrypt'],
    );

    const iv = getCrypto().getRandomValues(new Uint8Array(12));
    const encrypted = await getCrypto().subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: iv,
        },
        key,
        new TextEncoder().encode(data),
    );

    const combined = new Uint8Array(iv.length + encrypted.byteLength);
    combined.set(iv);
    combined.set(new Uint8Array(encrypted), iv.length);

    return arrayBufferToBase64(combined.buffer);
};

export const decryptClient = async (encryptedData: string): Promise<string> => {
    const cryptoSubtle = getCrypto().subtle;

    const key = await cryptoSubtle.importKey(
        'raw',
        await generateKey(secretKey),
        'AES-GCM',
        false,
        ['encrypt', 'decrypt'],
    );

    const combined = base64ToArrayBuffer(encryptedData);

    const iv = new Uint8Array(combined.slice(0, 12));
    const data = new Uint8Array(combined.slice(12));

    try {
        const decrypted = await cryptoSubtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv,
            },
            key,
            data,
        );

        return new TextDecoder().decode(decrypted);
    } catch (error) {
        console.error('Error decrypting:', error);
        throw error;
    }
};
