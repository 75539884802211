module.exports = {
    ALL: 'ALL',
    AIS: 'AIS',
    PIS: 'PIS',
    CBPII: 'CBPII',
    REDEEM_TOKEN: 'REDEEM_TOKEN',
    CUSTOMIZE_FLOW: 'CUSTOMIZE_FLOW',
    HIDE_CONSENT: 'HIDE_CONSENT',
    PARTNER: 'PARTNER',
    ACT_ON_BEHALF_OF: 'ACT_ON_BEHALF_OF',
    REQUEST_REFUND_ACCOUNT: 'REQUEST_REFUND_ACCOUNT',
    PERFORM_KYC: 'PERFORM_KYC',
    REFUND: 'REFUND',
    STANDING_ORDER: 'STANDING_ORDER',
    SINGLE_IMMEDIATE_PAYMENT: 'SINGLE_IMMEDIATE_PAYMENT',
    FUTURE_DATED: 'FUTURE_DATED',
    BULK_TRANSFER: 'BULK_TRANSFER',
    VARIABLE_RECURRING_PAYMENT: 'VARIABLE_RECURRING_PAYMENT',
    DO_NOT_ALLOW_API_KEY_AUTHENTICATION: 'DO_NOT_ALLOW_API_KEY_AUTHENTICATION',
    VIRTUAL_ACCOUNT: 'VIRTUAL_ACCOUNT',
};
