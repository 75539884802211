import merge from 'lodash/merge';
import React from 'react';
import logo from '../images/northmillBank.png';
import base from './base';

const fonts = ['https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600'];

const colors = {
    primary: '#9b72b2',
    secondary: 'var(--primary)',
    error: 'var(--bittersweet)',
    background: 'var(--ghost-white)',
    active: 'var(--active)',
    focus: '#9b72b2',
    hover: 'var(--hover)',
};

const extended = base(colors);

// Sets the the styles/properties for the Material UI components at a global level.
// If these styles/properties need to be overridden, it will have to be done in that file.
const BaseTheme = merge({}, extended.base, {
    props: {
        LogoWrapper: {
            partnerlogo: <img src={logo} />,
        },
    },
    overrides: {
        MuiDrawerItem: {
            root: {
                '& .listText': {
                    opacity: '1',
                },
            },
        },
        // Overrides the default height applied to partner logos.
        LogoWrapper: {
            mainLogo: {
                height: 40,
            },
        },
    },
});

BaseTheme.fonts = fonts;

const AltTheme = merge({}, BaseTheme, extended.alt);

export default {
    base: BaseTheme,
    alt: AltTheme,
};
