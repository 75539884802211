import merge from 'lodash/merge';
import React from 'react';
import logoN26 from '../images/logoN26.svg';
import base from './base';

const fonts = ['https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600'];

const colors = {
    primary: 'var(--n26-teal)',
    secondary: 'var(--n26-petrol)',
    error: 'var(--n26-rhubarb)',
    background: 'var(--gray-96)',
    focus: 'var(--n26-puerto-rico)',
    hover: 'var(--n26-puerto-rico)',
};

const extended = base(colors);

// Sets the the styles/properties for the Material UI components at a global level.
// If these styles/properties need to be overridden, it will have to be done in that file.
const BaseTheme = merge({}, extended.base, {
    props: {
        LogoWrapper: {
            partnerlogo: <img src={logoN26} />,
        },
    },
    overrides: {
        MuiDrawerItem: {
            root: {
                '& .listText': {
                    opacity: '1',
                },
            },
        },
    },
});

BaseTheme.fonts = fonts;

const AltTheme = merge({}, BaseTheme, extended.alt);

export default {
    base: BaseTheme,
    alt: AltTheme,
};
