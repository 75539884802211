const { TOKEN, NBANKA, SBERBANKSLOVENIA, PNBPER, PNB } = require('../../constants/banks');

export default function formatEmailForFirebaseUser(userEmail: string, scope: string) {
    // Replace NBANKA Scope with SBERBANKSLOVENIA - L2S-10
    if (scope === NBANKA) {
        scope = SBERBANKSLOVENIA;
    }

    // Replace PNBPER Scope with PNB - APE-2520
    if (scope === PNBPER) {
        scope = PNB;
    }

    if (scope !== TOKEN) {
        const index = userEmail.indexOf('@');
        const email = [
            userEmail.slice(0, index),
            '+',
            scope.toLowerCase(),
            userEmail.slice(index),
        ].join('');
        return email;
    }
    return userEmail;
}
