import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'mobx-react';
import App from 'next/app';
import Error from 'next/error';
import React from 'react';
import { ConfigCatProvider, createConsoleLogger, LogLevel } from 'configcat-react';
// eslint-disable-next-line import/no-unresolved
import '@polyfills';

import { getAppStore } from '../AppStore';
import CustomThemeProvider from '../components/common/theme/CustomThemeProvider';
import { appWithTranslation } from '../i18n';
import '../master.scss';
import sentry from '../sentry';
import TracerProvider from 'components/tracer-provider/TracerProvider';
import ClientErrorBoundary from 'components/client-error-boundary/ClientErrorBoundary';
import { SnackbarProvider } from 'notistack';
const { publicRuntimeConfig } = require('next/config').default();

const { captureException } = sentry(
    publicRuntimeConfig.sentry?.release,
    publicRuntimeConfig.sentry?.env,
);

class TokenApp extends App {
    state = {
        appStore: getAppStore(),
        logger: createConsoleLogger(LogLevel.Info),
    };

    componentDidMount() {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }

    static get isServer() {
        return typeof window === 'undefined';
    }

    static async getInitialProps(appContext) {
        const host = (this.isServer && appContext.ctx.req.headers.host) || window.location.host;
        try {
            let configcatKey = '';
            if (this.isServer) {
                configcatKey = process.env.CONFIGCAT_SDK_KEY;
            }

            const appProps = await App.getInitialProps(appContext);
            return { ...appProps, configcatKey, host };
        } catch (e) {
            // Capture errors that happen during a page's getInitialProps.
            // This will work on both client and server sides.
            captureException(e, appContext.ctx);
            return {
                host,
                pageProps: {
                    statusCode: e.statusCode,
                },
            };
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.appStore.setHost(props.host);
        state.appStore.setPath(props.router.pathname);

        return state;
    }

    static getDerivedStateFromError() {
        // React Error Boundary here allows us to set state flagging the error (and
        // later render a fallback UI).
        return {};
    }

    render() {
        const { Component, pageProps, configcatKey } = this.props;
        const { appStore, logger } = this.state;

        return (
            <>
                {pageProps.statusCode ? (
                    <Error statusCode={pageProps.statusCode} />
                ) : (
                    <ConfigCatProvider sdkKey={configcatKey} options={{ logger }}>
                        <Provider AppStore={appStore}>
                            <CustomThemeProvider>
                                <CssBaseline />
                                <SnackbarProvider>
                                    <TracerProvider>
                                        <ClientErrorBoundary AppStore={appStore}>
                                            <Component {...pageProps} AppStore={appStore} />
                                        </ClientErrorBoundary>
                                    </TracerProvider>
                                </SnackbarProvider>
                            </CustomThemeProvider>
                        </Provider>
                    </ConfigCatProvider>
                )}
            </>
        );
    }
}

export default appWithTranslation(TokenApp);
