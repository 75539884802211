import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import merge from 'lodash/merge';
import React, { forwardRef, LegacyRef, memo } from 'react';

const defaultStyles = {
    root: {
        '& .CheckboxIcon-background': {
            fill: '#F5F6FB',
        },
        '& .CheckboxIcon-check': {
            stroke: '#1c0f36',
        },
    },
};

const CheckboxIcon = memo(
    forwardRef((props: any, ref: LegacyRef<SVGSVGElement> | undefined) => {
        const classes = makeStyles(merge(defaultStyles, props.classes))();
        return (
            <svg
                width="30"
                height="30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={ref}
                className={clsx(classes.root)}>
                <rect width="30" height="30" rx="4" className="CheckboxIcon-background" />
                <path d="M9 14l4 4 8-8" strokeWidth="3" className="CheckboxIcon-check" />
            </svg>
        );
    }),
);

CheckboxIcon.displayName = 'CheckboxIcon';

export default CheckboxIcon;
