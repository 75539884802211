import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { inject } from 'mobx-react';
import React from 'react';
import { getTheme } from '../../../../src/themes';
import { IDefaultThemeProvider } from '../types';

const DefaultThemeProvider: React.FC<IDefaultThemeProvider> = ({ alt, AppStore, children }) => (
    <ThemeProvider theme={getTheme(AppStore?.scope, alt)}>{children}</ThemeProvider>
);

export default inject('AppStore')(DefaultThemeProvider);
