import { action, observable } from 'mobx';
import Router from 'next/router';
import { getScope } from '../../../scopes';

// constants
import {
    FORGOT_PASSWORD_USER_NOT_FOUND,
    GENERIC_ERROR,
    i18nMap,
    LOGIN_INVALID_CREDENTIALS,
    SIGNUP_ALREADY_EXISTS,
    SESSION_EXPIRED,
} from 'constants/errors';
import { ADMIN } from 'constants/banks';

import serverErrorMessageTransform from 'helpers/server-error-message-transform/serverErrorMessageTransform';
import { I18nMapKeys, Operation } from './types';

class ModalStore {
    @observable
    isOpen = false;
    @observable
    type = Operation.NOOP;
    @observable
    showServiceTicket = false;
    @observable
    serverMessage = false;
    @observable
    errorMessage = '';
    @observable
    hasSessionExpired = false;
    @observable
    tokenTraceId = '';
    @observable
    onCloseCallback = () => {};

    @action
    closeModal = () => {
        this.isOpen = false;
        this.onCloseCallback();
        if (this.hasSessionExpired) {
            Router.push('/signin');
        }
    };

    @action
    openModal = (type: Operation = Operation.NOOP) => {
        this.isOpen = true;
        this.type = type;
    };

    @action
    openErrorModal = (error?: any, errorCode?: I18nMapKeys, errorMessage?: string) => {
        this.isOpen = true;
        this.serverMessage = false;
        this.tokenTraceId = '';

        this.serverMessage = errorCode === GENERIC_ERROR;

        if (errorMessage) {
            this.errorMessage = errorMessage;
            return;
        }

        if (!errorCode && !error) {
            console.error('An errorCode or response was not provided.');
        }

        // token-trace-id
        const tokenTraceId = error?.response?.data?.metadata?.['token-trace-id'];
        if (tokenTraceId && tokenTraceId.length) {
            this.tokenTraceId = tokenTraceId[0];
        }

        if (error?.requestId) {
            this.showServiceTicket = false;
            this.serverMessage = true;
            this.errorMessage = JSON.stringify(error);
        } else if (errorCode === FORGOT_PASSWORD_USER_NOT_FOUND) {
            // Specified error, typically used for Forgot Password Screen
            this.showServiceTicket = false;
            this.errorMessage = i18nMap[errorCode];
        } else if (error?.response?.data?.details) {
            // Error from GRPC
            this.serverMessage = true;
            this.showServiceTicket = false;
            this.errorMessage = serverErrorMessageTransform(error.response.data.details);
        } else if (typeof error?.response?.data === 'string') {
            // Error from Token
            if (!(document.cookie as any).session && getScope(window.location.host) !== ADMIN) {
                this.serverMessage = false;
                this.showServiceTicket = true;
                this.errorMessage = i18nMap[SESSION_EXPIRED as I18nMapKeys];
                this.hasSessionExpired = true;
            } else {
                this.serverMessage = true;
                this.showServiceTicket = false;
                this.errorMessage = error.response.data;
            }
        } else if (errorCode && i18nMap[errorCode]) {
            // Specified error, typically used for Firebase errors
            this.showServiceTicket = ![LOGIN_INVALID_CREDENTIALS, SIGNUP_ALREADY_EXISTS].includes(
                errorCode,
            );
            this.errorMessage = i18nMap[errorCode];
        } else if (
            error?.message?.startsWith('CSV') ||
            error?.message?.startsWith('Registration')
        ) {
            // Specified error for bank config import or Specified error for bank registration register modal
            this.serverMessage = true;
            this.showServiceTicket = false;
            this.errorMessage = error.message;
        } else {
            // Fallback
            this.showServiceTicket = true;
            this.errorMessage = i18nMap[GENERIC_ERROR as I18nMapKeys];
        }
    };
}

export default ModalStore;
