// @ts-nocheck
import { Component, ErrorInfo } from 'react';
import TracerContext from 'components/tracer-provider/TracerContext';
import buildLightstepParams from 'helpers/build-lightstep-params/buildLightstepParams';
import buildLightstepErrorParams from 'helpers/build-lightstep-error-params/buildLightstepErrorParams';
import axios from 'axios';
import _get from 'lodash/get';

interface Props {
    AppStore: any;
}

class ClientErrorBoundary extends Component<Props> {
    static contextType = TracerContext;
    componentDidMount() {
        const { AppStore } = this.props;
        // This will create a span for each console error
        if (this.context.loaded) {
            const { tracer, initSpan, sessionId } = this.context;
            const _error = console.error as any;
            if (!_error.loaded) {
                console.error = function OverrideError(errorMessage: string) {
                    const errorParams = buildLightstepErrorParams(`${errorMessage}`);
                    const errorSpan = tracer.startSpan(`CONSOLE ERROR ${errorMessage}`, {
                        childOf: initSpan,
                        tags: {
                            errorMessage: `${errorMessage}`,
                            errorPriority: _get(errorParams, 'errorPriority', 'medium'),
                        },
                    });
                    errorSpan.finish();
                    _error.apply(console, arguments as any);

                    const userParams = buildLightstepParams(AppStore, errorParams);
                    axios.post(`/query-lightstep?sessionId=${sessionId}${userParams}`);
                };
                // @ts-ignore
                console.error.loaded = true;
            }
        }
    }

    componentDidCatch(error: Error, errorMessage: ErrorInfo) {
        const { AppStore } = this.props;
        if (this.context.loaded) {
            const { tracer, initSpan, sessionId } = this.context;
            const errorParams = buildLightstepErrorParams(`${errorMessage}`);
            const errorSpan = tracer.startSpan(`ERROR CATCH ${errorMessage}`, {
                childOf: initSpan,
                tags: {
                    errorMessage: `${errorMessage}`,
                    errorParam: error,
                    errorPriority: _get(errorParams, 'errorPriority', 'medium'),
                },
            });
            errorSpan.finish();

            const userParams = buildLightstepParams(AppStore, errorParams);
            axios.post(`/query-lightstep?sessionId=${sessionId}${userParams}`);
        }
    }

    render() {
        const { children } = this.props;
        return <>{children}</>;
    }
}

export default ClientErrorBoundary;
