module.exports = {
    DEACTIVATED: 'DEACTIVATED',
    GENERIC_ERROR: 'GENERIC_ERROR',
    LOGIN_INVALID_CREDENTIALS: 'LOGIN_INVALID_CREDENTIALS',
    LOGIN_SYSTEM_ERROR: 'LOGIN_SYSTEM_ERROR',
    PASSWORD_SYSTEM_ERROR: 'PASSWORD_SYSTEM_ERROR',
    PAYLOAD_GENERATE: 'PAYLOAD_GENERATE',
    RESEND_EMAIL: 'RESEND_EMAIL',
    SIGNUP_ALREADY_EXISTS: 'SIGNUP_ALREADY_EXISTS',
    FORGOT_PASSWORD_USER_NOT_FOUND: 'FORGOT_PASSWORD_USER_NOT_FOUND',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
};

module.exports.i18nMap = {
    DEACTIVATED: 'common.errors.generic.deactivated',
    GENERIC_ERROR: 'common:errors.generic.error',
    LOGIN_INVALID_CREDENTIALS: 'common:errors.login.invalidCredentials',
    LOGIN_SYSTEM_ERROR: 'common:errors.login.systemError',
    PASSWORD_EXPIRED: 'common:errors.login.passwordExpiredError',
    PASSWORD_SYSTEM_ERROR: 'common:errors.password.systemError',
    PAYLOAD_GENERATE: 'common:errors.payload.generate',
    RESEND_EMAIL: 'common:errors.resendEmail',
    SIGNUP_ALREADY_EXISTS: 'common:errors.signup.alreadyExist',
    FORGOT_PASSWORD_USER_NOT_FOUND: 'common:errors.forgotPasswordUserNotFound.genericError',
    SESSION_EXPIRED: 'common:errors.login.sessionExpiredError',
};
