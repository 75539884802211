import { SpanOptions } from 'opentracing';
import { createContext } from 'react';

type InitSpan = {
    setTag: (key?: string, value?: any) => void;
    finish: () => void;
};

type TracerType = {
    startSpan: (name?: string, options?: SpanOptions) => InitSpan;
};

type TracerState = {
    loaded?: boolean;
    initSpan?: InitSpan;
    sessionId?: string;
    tracer?: TracerType;
};

const TracerContext = createContext<TracerState>({ loaded: false });

export default TracerContext;
