const { publicRuntimeConfig } = require('next/config').default();

const getLinks = (route = '/') => {
    const splitRoutes = route.split('/');
    const baseRoute = `/${splitRoutes[1]}`;
    const newLinkList = JSON.parse(JSON.stringify(publicRuntimeConfig.links.admin));

    _findAndUpdateLink(newLinkList, baseRoute);

    return newLinkList;
};

const _selectLink = (linkList, linkOnly, parentLink) => {
    return Object.values(linkList).find((value) => {
        if (value.link === linkOnly) {
            value['selected'] = true;
            if (parentLink) parentLink['selected'] = true;
            return true;
        } else if (value.menuWithSublink && typeof value.subLinks === 'object') {
            return _selectLink(value.subLinks, linkOnly, value);
        } else {
            return false;
        }
    });
};

function _findAndUpdateLink(linkList, currLink) {
    const linkOnly = currLink.split('?')[0];
    const link = _selectLink(linkList, linkOnly);
    if (!link && currLink === '/') {
        linkList.dashboard.selected = true;
    }
}

const isPathAvailableForOktaGroup = (groups, link) => {
    let isAvailable = true;
    const { adminLinksOktaGroups, links } = publicRuntimeConfig;
    const key = _findLinkKey(links.admin, link);
    if (adminLinksOktaGroups[key] && adminLinksOktaGroups[key].groups.length) {
        isAvailable = adminLinksOktaGroups[key].groups.some((group) => groups.includes(group));
    }
    return isAvailable;
};

const _findLinkKey = (linkList, currLink) => {
    for (const link in linkList) {
        if (linkList[link].link === currLink) {
            return link;
        }

        if (linkList[link].subLinks) {
            const subLink = _findLinkKey(linkList[link].subLinks, currLink);

            if (subLink) {
                return link;
            }
        }
    }
};

module.exports = {
    getLinks,
    _LINKS: publicRuntimeConfig.links.admin,
    isPathAvailableForOktaGroup,
};
