import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import lightstep from 'lightstep-tracer';
import root from 'window-or-global';
import { hri } from 'human-readable-ids';

const opentracing = require('opentracing');
const serviceName = 'developer-portal-service';

let initSpan, tracer, lsTracer;
const initializeTracer = (accessToken) => {
    lsTracer = new lightstep.Tracer({
        component_name: serviceName,
        access_token: accessToken,
        propagators: {
            [opentracing.FORMAT_HTTP_HEADERS]: new lightstep.B3Propagator(),
            [opentracing.FORMAT_TEXT_MAP]: new lightstep.B3Propagator(),
            [opentracing.FORMAT_BINARY]: new lightstep.B3Propagator(),
        },
    });

    opentracing.initGlobalTracer(lsTracer);
    tracer = opentracing.globalTracer();
};

export function onRequest(request) {
    const requestSpan = tracer.startSpan(`CLIENT ${request.method.toUpperCase()} ${request.url}`, {
        childOf: initSpan,
        tags: {
            url: document.location.href,
        },
    });
    request.headers['x-b3-traceid'] = requestSpan.context()._traceGUID;
    request.headers['x-b3-spanid'] = requestSpan.context()._guid;
    request.headers['x-b3-sampled'] = '1';
    request.headers['x-current-url'] = window.location.href;
    requestSpan.finish();

    return request;
}

export default function TracerLoader({ setTracerState }) {
    const router = useRouter();
    const [isTracerInitialized, setIsTracerInitialized] = useState(false);

    useEffect(() => {
        async function fetch() {
            setIsTracerInitialized(false);
            const response = await axios.get('/api/auth/env-variable?key=lightStepAccessToken');
            const { lightStepAccessToken } = response.data;

            const sessionId = hri.random();
            initializeTracer(lightStepAccessToken);

            initSpan = tracer.startSpan(`dashboard-session-${sessionId}`, {
                tags: {
                    'session-id': sessionId,
                },
            });

            axios.interceptors.request.use(onRequest);

            setTracerState({
                initSpan,
                sessionId,
                tracer,
                loaded: true,
            });
            setIsTracerInitialized(true);
        }
        fetch();
    }, []);

    useEffect(() => {
        if (isTracerInitialized) {
            const routeSpan = tracer?.startSpan(`ROUTE ${root.document.location.pathname}`, {
                childOf: initSpan,
                tags: {
                    url: document.location.href,
                },
            });
            routeSpan?.finish();
        }
    }, [router.asPath, isTracerInitialized]);

    return null;
}
