import merge from 'lodash/merge';
import base from './base';

const fonts = ['https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600'];

const colors = {
    primary: 'var(--primary)',
    secondary: 'var(--primary)',
    error: 'var(--bittersweet)',
    background: 'var(--ghost-white)',
    active: 'var(--active)',
    focus: 'var(--primary)',
    hover: 'var(--hover)',
};

const extended = base(colors);

const BaseTheme = merge({}, extended.base);

BaseTheme.fonts = fonts;

const AltTheme = merge({}, BaseTheme, extended.alt);

export default {
    base: BaseTheme,
    alt: AltTheme,
};
