module.exports = {
    ADMIN: 'ADMIN',
    AIRWALLEX: 'AIRWALLEX',
    ANPOST: 'ANPOST',
    AVANTCARD: 'AVANTCARD',
    BNPP: 'BNPP',
    COOP: 'COOP',
    ELAVONFS: 'ELAVONFS',
    GHANABANK: 'GHANABANK',
    GOHENRY: 'GOHENRY',
    NORTHMILL: 'NORTHMILL',
    PNB: 'PNB',
    QIBUK: 'QIBUK',
    SOLDO: 'SOLDO',
    SOLDOIRL: 'SOLDO-IRL',
    SUMUP: 'SUMUP',
    SUMUPIE: 'SUMUP-IE',
    TALKREMIT: 'TALKREMIT',
    TBB: 'TBB',
    TMG: 'TMG',
    TOKEN: 'TOKEN',
    TURKISHBANKUK: 'TURKISHBANKUK',
    UNITYTRUST: 'UNITYTRUST',
    PNBBUS: 'PNB-BUS',
    PNBPER: 'PNB-PER',
    N26: 'N26', // For tests only
};
