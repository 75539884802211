import React, { useState } from 'react';
import TracerContext from './TracerContext';
import TracerLoader from 'components/tracer-provider/TracerLoader';

interface Props {
    children: React.ReactNode;
}

export default function TracerProvider({ children }: Props) {
    const [tracerState, setTracerState] = useState({}) as any;
    return (
        <TracerContext.Provider value={tracerState}>
            <TracerLoader setTracerState={setTracerState} />
            {children}
        </TracerContext.Provider>
    );
}
