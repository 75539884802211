import _get from 'lodash/get';

export default function buildLightstepParams(AppStore: any, additionalParams: any = {}) {
    if (!AppStore) {
        return '';
    }

    const params = {
        ['user-scope']: _get(AppStore, 'user.scope'),
        ['user-role']: _get(AppStore, 'user.role'),
        ['member-id']: _get(AppStore, 'member.memberId'),
        ['member-type']: _get(AppStore, 'member.memberType'),
        ['client-status']: _get(AppStore, 'client.clientStatus'),
        ['client-type']: _get(AppStore, 'client.clientType'),
        ['dashboard-session']: 'dashboard-session',
        ...additionalParams,
    } as any;

    return Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            return acc + `&${key}=${params[key]}`;
        } else {
            return acc;
        }
    }, '');
}
