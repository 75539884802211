const NextI18Next = require('next-i18next').default;
const BANKS = require('./constants/banks');

const {
    Link,
    Router,
    i18n,
    config,
    useTranslation,
    withTranslation,
    appWithTranslation,
} = new NextI18Next({
    preload: ['en'],
    fallbackLng: 'en',
    defaultLanguage: 'en',
    otherLanguages: ['en', 'fr'],
    saveMissing: true,
    localePath: 'public/static/locales',
});

const NAMESPACES = {
    app: true,
    'home-dashboard': true,
    'sandbox-main': true,
    signup: true,
    swaggerText: true,
};

// Load all the namespaces only on the server
if (typeof window === 'undefined') {
    for (let bank in BANKS) {
        bank = bank.replace(/-/g, '');
        for (const ns in NAMESPACES) {
            config.ns.push(`${bank}/${ns}`);
        }
        config.ns.splice(config.ns.indexOf(bank), 1);
    }
}

function _getNamespaces(namespaces, scope) {
    scope = scope.replace(/-/g, '');
    switch (scope) {
        case BANKS.TOKEN:
            return namespaces;
        default:
            return namespaces.map((namespace) => {
                if (
                    (namespace === 'reset-password' || namespace === 'check-email') &&
                    scope === BANKS.BNPP &&
                    i18n.language === 'fr'
                ) {
                    return `${scope}/${namespace}`;
                }
                return NAMESPACES[namespace] ? `${scope}/${namespace}` : namespace;
            });
    }
}

// Not adding Trans, do not use! We had issues with formatting and testing.
module.exports = {
    Link,
    Router,
    i18n,
    config,
    useTranslation,
    withTranslation,
    appWithTranslation,
    _getNamespaces,
};
