import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

(async () => {
    try {
        if (!firebase.apps.length) {
            const response = await axios.get(
                '/api/auth/env-variable?key=firebaseApiKey&key=firebaseAuthDomain',
            );
            const { firebaseApiKey: apiKey, firebaseAuthDomain: authDomain } = response.data;
            firebase.initializeApp({ apiKey, authDomain });
        }
    } catch (error) {
        console.error('Error initializing Firebase:', error);
    }
})();

module.exports = firebase;
