import { createMuiTheme } from '@material-ui/core/styles';
import BANKS from '../constants/banks';

const { TOKEN } = BANKS;
const themes = {};

for (let bank in BANKS) {
    bank = bank.replace(/-/g, '');
    themes[bank] = require(`./${bank}Theme`).default;
}

export const getTheme = (id = TOKEN, alt = false) => {
    const theme = themes[id.replace(/-/g, '')] || themes[TOKEN];
    return createMuiTheme(theme[(alt && 'alt') || 'base']);
};
